
    import { defineComponent } from 'vue';
    import { IonList, IonItem, IonLabel, IonListHeader } from '@ionic/vue';
    import { store } from '../../store/store';
    import { chevronForwardSharp } from 'ionicons/icons';
    import { LanguageMixin } from '@/mixins/LanguageMixin';
    import { SharedMixin } from '../../mixins/SharedMixin';

    export default defineComponent({
        name: 'Settings',
        mixins: [LanguageMixin, SharedMixin],
        components: {
            IonList, IonItem, IonLabel, IonListHeader
        },
        setup() {
            let loggedInUser = {};
            const toggleEventStatus = true as boolean;
            const togglePublicationsStatus = true as boolean;

            loggedInUser = store.state.loggedInUser

            return {
                chevronForwardSharp,
                loggedInUser,
                toggleEventStatus,
                togglePublicationsStatus
            }
        }
    })